import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import { EventLayout } from '../events'
import {  Jumbotron, Media, Button,  Modal, ModalHeader, ModalBody, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap'
import ReactHtmlParser from 'react-html-parser'
import ReactPlayer from 'react-player'
import qs from 'query-string'
import { handleGetEventDetails, handleRegisterEvent } from '../../services/api'
import { BarLoader } from 'react-spinners'
import {navigate}  from '@reach/router'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon
} from 'react-share';
import validator from 'validator'
import moment from 'moment'
import { UncontrolledAlert } from 'reactstrap'
import { isLoggedIn } from "../../services/auth"
import ReCAPTCHA from "react-google-recaptcha";

const override = `
    margin: 0 auto;
`
const user = isLoggedIn() ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : false

export default class view extends Component {
    constructor(props){
        super(props)

        let queryData = null

        let status = false
        let message = ""
        let style = {}

        if(props.location.search !== ""){
            queryData = qs.parse(props.location.search, { ignoreQueryPrefix: true })
        }

        // TODO: Add form states and validation states for the event registration
        this.state = {
            data : null,
            id : !queryData ? null : queryData.k,

            modal : false,

            status : status,
            statusMessage : message,
            style : style,

            registration: {
                firstName : isLoggedIn() ? user.userData.attributes.given_name : "",
                lastName : isLoggedIn() ? user.userData.attributes.family_name : "",
                email : isLoggedIn() ? user.userData.attributes.email : "",
                phoneNumber: isLoggedIn() ? (!user.userData.attributes.phone_number ? '' : user.userData.attributes.phone_number) : "",
                eventId : !queryData ? null : queryData.k,
                organization: isLoggedIn() ? user.userData.attributes['custom:organization'] : "",
            },

            formValid : isLoggedIn() ? true : false,
            captcha : "",
            recaptcha : true,
        }

        this.modal = this.modal.bind(this)

    }

    // Load Event data asynchronously
    async componentDidMount(){
        
        let data = await handleGetEventDetails({event_id : this.state.id})

        if(data.length === 0){
            await navigate('/events/' , {state : {status : "danger", message : "The event post you are trying to access is either deleted or moved."}})
            if(typeof window !== "undefined"){
                window.location.reload()
            } 
        }

        this.setState({
            data : data[0]
        })
    }

    captchaChange = (value) => {
        const token = value
  
    this.setState({
        captcha : token
    }, () => {
        this.validateForm()
    })
    }

    modal(){
        this.setState({
            modal : !this.state.modal,
            status: false,
            captcha: "",
            formValid: false
        })
    }

    handleChange = (event) => {
        let name = event.target.id
        let value = event.target.value

        this.setState({
            registration: {
                ...this.state.registration,
                [name] : value
            }
        }, () => {
            this.validateForm()
        })
    }

    validateForm(){
        var tempFormValid = true;
        Object.keys(this.state.registration).map(x => {
            if(x != 'event_id' && x != 'organization'){
                if(this.state.registration[x] == ''){
                    tempFormValid = false
                    return;
                }
            }
        });
        this.setState({
            formValid : tempFormValid && !!this.state.captcha
        })
    }

    resetForm(){
        this.recaptcha.reset();
        this.setState({
            registration: {
                ...this.state.registration,
                firstName : isLoggedIn() ? user.userData.attributes.given_name : "",
                lastName : isLoggedIn() ? user.userData.attributes.family_name : "",
                email : isLoggedIn() ? user.userData.attributes.email : "",
                phoneNumber: isLoggedIn() ? (!user.userData.attributes.phone_number ? '' : user.userData.attributes.phone_number) : "",
                organization: isLoggedIn() ? user.userData.attributes['custom:organization'] : "",
            },
            captcha: ""
        }, () => {
            this.validateForm()
        });
    }

    // TODO: Create handler for event registration
    submitHandler = async (event) => {
        event.preventDefault()
        await handleRegisterEvent(this.state.registration)
        .then((res) => {
            if(res.success){
                this.setState({
                    isLoading: false,
                    status : "success",
                    message : "Register Succesfully"
                })
                this.resetForm();
            }else{
                this.setState({
                    isLoading: false,
                    status : "danger",
                    message : res.message
                })
            }
        })
        .catch(err => {
            this.setState({
                isLoading: false,
                status : "danger",
                message : err
              })
        }) 
    } 

    renderRegBtn() {
        //registration end one day before the event start date
        var today = new Date()
        if((Date.parse(this.state.data.registration_end_date) > Date.parse(today)) && this.state.data.status == 'OPEN' && (this.state.data.capacity == 'unlimited' || this.state.data.total_reg < this.state.data.capacity)){
            return (
                <Button 
                color="info"
                onClick={(event) => {
                    event.preventDefault()
                    this.modal()
                }}>
                Event Registration
                </Button>
            );
        }else
            return (
                <div><i>Event registration is close</i></div>
            );
      }

    render() {
        return (
            <EventLayout location={this.props.location}>
            {!this.state.data ? (
                <div className="text-center" style={{margin:`auto`, padding:`1.5rem 0`}}>
                    {`Loading Event details...`}
                    <BarLoader
                        sizeUnit={"px"}
                        width={250}
                        color={'#123abc'}
                        css={override}
                    />
                </div>
            ) : (
                <Jumbotron>
                    <Media>
                        <Media src={this.state.data.main_image} style={{maxWidth: `100%`,width: `100%`}}/>
                    </Media>
                    <h3 className="display-3">
                        {this.state.data.title}
                    </h3>
                    <hr className="my-2" />
                    <font style={{fontSize:`1rem`}}><i>{moment(this.state.data.start_date).format('DD MMMM YYYY')}</i></font>
                    <br />
                    <br />

                    <div className="lead">
                        { ReactHtmlParser(this.state.data.content) }
                    </div>
                    <div >
                        { this.state.data.video ? (<ReactPlayer url={this.state.data.video} playing controls={true} style={{margin:`auto`}}/>) : null }
                    </div>
                    <br />
                    <div className="clearfix">
                        <div className="float-left">
                            <FacebookShareButton
                                className="float-left"
                                children="span"
                                url={process.env.BASE_URL + 'events/view/?k=' + this.state.data.event_id}
                                >
                            <FacebookIcon size={45} round/>
                            </FacebookShareButton>
                            <TwitterShareButton
                                className="float-left"
                                style={{display:`relative`}}
                                url={process.env.BASE_URL + 'events/view/?k=' + this.state.data.event_id}
                                >
                                <TwitterIcon size={45} round/>
                            </TwitterShareButton>
                            <LinkedinShareButton
                                className="float-left"
                                style={{display:`relative`}}
                                url={process.env.BASE_URL + 'events/view/?k=' + this.state.data.event_id}
                                >
                                <LinkedinIcon size={45} round/>
                            </LinkedinShareButton>
                            <TelegramShareButton
                                className="float-left"
                                style={{display:`relative`}}
                                url={process.env.BASE_URL + 'events/view/?k=' + this.state.data.event_id}
                                >
                                <TelegramIcon size={45} round/>
                            </TelegramShareButton>
                            <WhatsappShareButton
                                className="float-left"
                                style={{display:`relative`}}
                                url={process.env.BASE_URL + 'events/view/?k=' + this.state.data.event_id}
                                >
                                <WhatsappIcon size={45} round/>
                            </WhatsappShareButton>
                            <EmailShareButton
                                className="float-left"
                                style={{display:`relative`}}
                                url={process.env.BASE_URL + 'events/view/?k=' + this.state.data.event_id}
                                >
                                <EmailIcon size={45} round/>
                            </EmailShareButton>
                        </div>
                        <div className="float-right">
                            {this.renderRegBtn()}
                        {/* TODO: Form fields and handlers */}
                            <Modal isOpen={this.state.modal} toggle={this.modal} className={this.props.className}>
                                <ModalHeader toggle={this.modal}>Event Registration</ModalHeader>
                                
                                <ModalBody>
                                    <div>
                                    {this.state.status ? (
                                        <UncontrolledAlert  color={this.state.status}>{this.state.message}</UncontrolledAlert>
                                    ) : (
                                        null
                                    )}
                                    </div>
                                    <p>
                                        Please fill up the following fields to register for this event
                                    </p>
                                    
                                    <Form onSubmit={this.submitHandler}> 
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <FormGroup>
                                                    <Label for="firstName">
                                                        First Name
                                                    </Label>
                                                    <Input
                                                        required
                                                        type="text"
                                                        name="firstName"
                                                        id="firstName"
                                                        value={this.state.registration.firstName}
                                                        placeholder="First Name"
                                                        onChange={this.handleChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <FormGroup>
                                                    <Label for="lastName">
                                                        Last Name
                                                    </Label>
                                                    <Input
                                                        required
                                                        type="text"
                                                        name="lastName"
                                                        id="lastName"
                                                        placeholder="Last Name"
                                                        value={this.state.registration.lastName}
                                                        onChange={this.handleChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <FormGroup>
                                            <Label for="email">
                                                E-mail Address
                                            </Label>
                                            <Input
                                                type="email"
                                                name="email"
                                                id="email"
                                                required
                                                placeholder="E-mail Address"
                                                value={this.state.registration.email}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="phoneNumber">
                                                Phone Number
                                            </Label>
                                            <Input
                                                type="text"
                                                name="phoneNumber"
                                                id="phoneNumber"
                                                required
                                                placeholder="Phone Number"
                                                value={this.state.registration.phoneNumber}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <ReCAPTCHA
                                            sitekey={process.env.CAPTCHA_SITEKEY}
                                            onChange={this.captchaChange}
                                            ref={(r) => this.recaptcha = r}
                                            />
                                        </FormGroup>

                                        <Button
                                            color={this.state.formValid ? 'success' : 'secondary'}
                                            className="float-right"
                                            disabled={!this.state.formValid}
                                        >
                                            Continue
                                        </Button>{' '}
                                        <br />
                                    </Form>
                                </ModalBody>
                                
                            </Modal>
                        </div>
                    </div>
                    
                </Jumbotron>
            )}
                
            </EventLayout>
        )
    }
}

// NOT USED
export class ViewContent extends Component {
    constructor(props){
        super(props)

        console.log(props)
        this.state = {
            data : this.props.data
        }
    }

  render() {

    const value = this.state.data
    return (
        <Jumbotron>
            <Media>
                <Media src={value.image} style={{maxWidth: `100%`,width: `100%`}}/>
            </Media>
            <h3 className="display-3">
                {value.title}
            </h3>
            <hr className="my-2" />
            <font style={{fontSize:`1rem`}}><i>{value.start}</i></font>
            <br />
            <br />

            <p className="lead">
                { ReactHtmlParser(value.content) }
            </p>
            <p >
                { value.video ? (<ReactPlayer url={value.video} playing controls={true} style={{margin:`auto`}}/>) : null}
            </p>
            <br/>
        </Jumbotron>
    )
  }
}